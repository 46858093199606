<template>
    <div class="NearbyConst">
        <nodata v-if="constList.length === 0"></nodata>
        <!-- 加载中 -->
        <loading v-if="isLoading" :full="true" :title="$t('mx_mobile.Client.1585281647545')" />
        <div class="container" ref="container"></div>
        <div class="custMapList">
            <div class="choose" v-if="haveAddress">
                <van-button round size="small" v-for="(item,index) in distanceList" :key="index" class="btn" @click="choosedistance(index)" :class="{'show':item.active}">
                    <span class="fons">
                        {{item.distance}}公里
                    </span>
                </van-button>
            </div>
            <ul ref="custMap"></ul>
            <div class="other" v-if="!loadingList && constList.length > 0">
                <span v-if="hasPage" @click="pageNext" style="color:#303133;">
                    <!-- 换一批 -->
                    {{$t('mx_mobile.Client.1627021170437')}}
                </span>
                <span v-else>
                    <!-- 没有更多了 -->
                    {{$t('mx_mobile.Client.1585279987650')}}
                </span>
            </div>
            <!-- 加载中 -->
            <loading class="loading" v-show="loadingList" :title="$t('mx_mobile.Client.1585281647545')" />
        </div>

    </div>
</template>
<script>
import titleMixin from '@/mixin/title'
export default {
    name: 'NearbyConst',
    mixins: [titleMixin],
    // title: '附近客户',
    title: function fc() { return this.$t('mx_mobile.Client.1585630298302') },
    data() {
        return {
            haveAddress: false,
            isLoading: true,
            loadingList: true,
            totalNum: 0,
            constList: [],
            dataPush: [],
            distanceList: [{ distance: 20, active: true }, { distance: 50, active: false }, { distance: 100, active: false }],
            distance: '20km',
            map: null,
            custId: null,
            paging: {
                pageN: 1,
                pageSize: 10
            }
        }
    },
    created() {
        this.setMenu()
    },
    mounted() {
        this.init()
    },
    computed: {
        hasPage: function () {
            return Math.ceil(this.totalNum / this.paging.pageSize) !== this.paging.pageN
        }
    },
    methods: {
        choosedistance(index) {
            this.distanceList.forEach((m, i) => {
                if (i == index) {
                    this.distance = m.distance + 'km'
                    m.active = true
                } else {
                    m.active = false
                }
            })
            this.getNearbyConst()
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        init() {
            this.custId = this.$route.params.id
            this.getNearbyConst()
        },
        getNearbyConst() {
            if (!this.custId) { return }
            this.loadingList = true
            const url = this.Global.config.apiBaseURL + this.Global.api.v2.customerNearby_do
            let params = {
                pageN: this.paging.pageN,
                pageSize: this.paging.pageSize,
                custId: this.custId, // 客户ID
                distance: this.distance // 距离
            }
            this.axios.get(url, { params })
                .then(res => {
                    this.isLoading = false
                    this.loadingList = false
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        // console.log(res.data.data)
                        let list = res.data.data.list
                        this.totalNum = res.data.data.totalNum
                        list.forEach(item => { // 删除不要的字段
                            delete item.custContacts
                            delete item.custTracks
                            delete item.dynamics
                        })
                        this.constList = list
                        if (this.dataPush.length === 0) {
                            this.setMap()
                        } else {
                            // console.log(this.constList)
                            this.mapDataPush()
                        }
                        this.haveAddress = true
                    } else {
                        this.haveAddress = false
                        this.$toast.fail(res.data.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
        },
        setMap() {
            let _this = this
            // 创建地图
            this.map = new AMap.Map(_this.$refs.container, {
                zoom: 9
            })

            AMapUI.loadUI(['misc/MarkerList', 'overlay/SimpleMarker'], (MarkerList, SimpleMarker) => {
                var markerList = new MarkerList({
                    // 关联的map对象
                    map: _this.map,

                    // 列表的dom容器的id
                    listContainer: _this.$refs.custMap,

                    // 选中状态（通过点击列表或者marker）时在Marker和列表节点上添加的class，可以借此编写css控制选中时的展示效果
                    selectedClassNames: 'selected',

                    // 返回数据项的Id
                    getDataId: function (dataItem, index) {
                        // index表示该数据项在数组中的索引位置，从0开始，如果确实没有id，可以返回index代替
                        return dataItem.id
                    },
                    // 返回数据项的位置信息，需要是AMap.LngLat实例，或者是经纬度数组，比如[116.789806, 39.904989]
                    getPosition: function (dataItem) {
                        return dataItem.position
                    },
                    // 返回数据项对应的Marker
                    getMarker: function (data, context, recycledMarker) {
                        var defaultIconStyle = function (index) {
                            return 'red-' + (index + 1)
                        }
                        if (recycledMarker) {
                            recycledMarker.setIconStyle(defaultIconStyle(context.index))
                            return
                        }
                        return new SimpleMarker({
                            iconTheme: 'numv1',
                            containerClassNames: 'my-marker',
                            iconStyle: defaultIconStyle(context.index)
                        })
                    },
                    // 返回数据项对应的列表节点
                    getListElement: function (dataItem, context, recycledListElement) {
                        var arr = [dataItem.custLevel, dataItem.custSource, dataItem.tradeType, dataItem.custStage]
                        let descr = arr.filter(item => {
                            return item != ''
                        }).join('、')
                        // console.log(descr)
                        descr = descr === '' ? '暂无' : descr

                        var distance = Number(dataItem.c_distance).toFixed(0)
                        let about = '约'
                        var distanceStr = distance > 1000 ? about + (distance / 1000).toFixed(1) + 'km' : about + distance + 'm'
                        var tpl = '<p><%- dataItem.id %>. <%- dataItem.desc %><div title=' + descr + '>' + descr + '<div class="distance">' + distanceStr
                        var content = MarkerList.utils.template(tpl, {
                            dataItem: dataItem,
                            dataIndex: context.index
                        })

                        if (recycledListElement) {
                            // 存在可回收利用的listElement, 直接更新内容返回
                            recycledListElement.innerHTML = content
                            return recycledListElement
                        }

                        // 返回一段html，MarkerList将利用此html构建一个新的dom节点
                        return '<li>' + content + '</li>'
                    }

                })

                // 监听Marker和ListElement上的点击
                markerList.on('markerClick listElementClick', function (event, record) {
                    let item = _this.constList[record.index]
                    if (item && item.custId) {
                        let url = '/client/detail/' + item.custId
                        _this.$router.push(url)
                    }
                })

                // 构建一个数据项数组，数据项本身没有格式要求，但需要支持getDataId和getPosition
                window.markerList = markerList
                _this.mapDataPush()
            })
        },
        mapDataPush() {
            let _this = this
            _this.dataPush = []
            _this.constList.forEach((item, index) => {
                let a1 = item.location.split(',')
                _this.dataPush.push({
                    id: index + 1,
                    position: [a1[1], a1[0]],
                    desc: item.custName,
                    custLevel: item.custLevel || '',
                    custSource: item.custSource || '',
                    tradeType: item.tradeType || '',
                    custStage: item.custStage || '',
                    c_distance: item.c_distance || ''
                })
            })
            markerList.render(_this.dataPush)
        },
        pageNext() {
            this.paging.pageN++
            this.getNearbyConst()
        },
        // 清空
        clear() {
            this.map && this.map.destroy()
            this.map = null
            this.custId = null
            this.constList = []
            this.dataPush = []
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else {
            this.clear()
            next()
        }
    },
    watch: {
        $route() {
            // 初始
            if (this.$route.path.indexOf('/client/nearbyConst') > -1) {
                this.setMenu()
                this.custId = this.$route.params.id
                this.getNearbyConst()
            }
        }
    }
}
</script>

<style lang="less" scoped>
.NearbyConst {
    bottom: 0;
    background: #fff;
    .show {
        background-color: #d0021b !important;
        color: white !important;
    }
    .container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 333px;
        // border:1px solid red;
    }
    .custMapList {
        // border:1px solid blue;
        height: 333px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        .choose {
            border-bottom: 1px solid #eee;
            padding: 10px 0;
            text-align: center;
            .btn {
                background-color: #f2f3f4;
                margin-right: 10px;
            }
            .van-button--small {
                width: 82px;
                height: 30px;
            }
            .fons {
                font-size: 14px;
            }
        }
        .loading {
            text-align: center;
        }
    }
    .other {
        text-align: center;
        color: #909399;
        font-size: 0.65rem;
        > span {
            // background: red;
            padding: 8px 12px;
            border-radius: 5px;
            &:active {
                background: #eee;
            }
        }
    }
}
</style>
